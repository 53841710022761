import styled from 'styled-components'

import { cardEnterAnim, IconButton } from '../../globalStyles'
import { rhythm } from '../../utils/typography'
import { mediaQueries } from '../../vars'

export const Container = styled.section`
  border-radius: 10px;
  margin: 0 auto;
  max-width: 1000px;
  /* Animation fill mode backwards is important because if transform: scale is on this element its children cannot be fixed */
  animation: ${cardEnterAnim} 0.6s 0.2s backwards;
  position: relative;
  background: #ffffffdd;

  @media ${mediaQueries.general.desktop} and (orientation: landscape) {
    padding: ${rhythm(1)};
    box-shadow: var(--box-shadow);
  }
`

export const BackButton = styled(IconButton)`
  animation: ${cardEnterAnim} 0.6s 0.2s both;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: var(--box-shadow-very-light);
  border-radius: 10px 0 10px 0;
  padding-right: ${rhythm(1 / 2)};

  @media ${mediaQueries.portrait.handheld} {
    position: sticky;
    border-radius: 0 0 10px 0;
    z-index: 1;
    top: 0;
    left: 0;
    background: #ffffffdd;
    padding: 0;
  }
`

export const Header = styled.header`
  margin: ${rhythm(1)} auto;
  border-bottom: 3px solid #00000011;
  padding: ${rhythm(1.5)};

  h1 {
    margin: 0 auto;
    width: fit-content;

    span {
      color: #666;
    }
  }

  @media ${mediaQueries.general.handheld} {
    padding: 0;
    padding-bottom: ${rhythm(1)};
  }
`

export const ErrorMsg = styled.p`
  margin: ${rhythm(1)};
  color: var(--black);
  background: #ff000066;
  padding: ${rhythm(1)};
  border: 1px solid red;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  font-size: 1.1rem;
`
