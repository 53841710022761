import styled, { keyframes } from 'styled-components'
import { rhythm } from '../../../utils/typography'

const enter = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Container = styled.section`
  animation: ${enter} 0.6s 0.2s both;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: ${rhythm(2)};
  padding: ${rhythm(1)} 0;
`

export const ShippingFieldset = styled.fieldset`
  padding: ${rhythm(1)};
  padding-top: 0;
  border: none;
`

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  justify-content: space-around;
  width: 90%;
  height: fit-content;
  box-shadow: var(--box-shadow-light);
  border-radius: 10px;
  padding: ${rhythm(2)};

  label {
    color: #555;
    font-size: 1.2rem;

    span {
      font-size: 2rem;
      font-weight: bold;
      display: block;
      color: initial;
    }
  }

  button {
    font-size: 1.2rem;
    font-weight: bold;
    border: 1px solid var(--primary);
    width: 100%;
    cursor: pointer;
    background: #fff;
    padding: ${rhythm(1 / 4)};
    margin-top: ${rhythm(1 / 1)};
    box-shadow: var(--box-shadow-light);
  }
`

export const Pending = styled.div`
  display: flex;
  align-items: center;
  margin: ${rhythm(1)} auto;
  width: fit-content;
  max-width: 80%;
  font-size: 1.2rem;
  animation: ${enter} 0.6s 0.2s both;

  img {
    height: 2rem;
    margin: 0;
    margin-left: 0.5rem;
  }
`
