import styled from 'styled-components'
import { rhythm } from '../../../utils/typography'
import { RadioInput } from '../../common/RadioInput'

export const Radio = styled(RadioInput)`
  label {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const Description = styled.span`
  font-size: 0.9rem;
  color: #333;
`

export const Price = styled.span`
  font-size: 1.4rem;
  font-weight: lighter;
  display: flex;
  line-height: 1.4rem;
  margin: ${rhythm(1 / 3)} 0;
`

export const DollarSign = styled.span`
  font-size: 1.1rem;
  line-height: 1.1rem;
  align-self: flex-start;
  font-weight: normal;
`

export const Estimate = styled.span`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
  align-self: flex-end;
  margin-left: 5px;
`
