import { IFormInfo } from '../../../@types'

export enum FormInfoActions {
  Name = 'name',
  Email = 'email',
  Phone = 'phone',
  Line1 = 'line1',
  Line2 = 'line2',
  City = 'city',
  Province = 'province',
  PostalCode = 'postalCode',
}

export interface IAction {
  type: FormInfoActions
  newValue: string
}

export function formInfoReducer(state: IFormInfo, action: IAction): IFormInfo {
  switch (action.type) {
    case FormInfoActions.Name:
      return { ...state, name: action.newValue }

    case FormInfoActions.Email:
      return { ...state, email: action.newValue }

    case FormInfoActions.Phone:
      return { ...state, phone: action.newValue }

    case FormInfoActions.Line1:
      return { ...state, line1: action.newValue }

    case FormInfoActions.Line2:
      return { ...state, line2: action.newValue }

    case FormInfoActions.City:
      return { ...state, city: action.newValue }

    case FormInfoActions.Province:
      return { ...state, province: action.newValue }

    case FormInfoActions.PostalCode:
      return { ...state, postalCode: action.newValue }

    default:
      return state
  }
}
