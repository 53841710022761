import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useMediaQuery } from '../../hooks/useMediaQuery'

import backArrow from '../../../static/svg/chevron-left.svg'

import {
  cart,
  IRedirectToCheckoutItem,
  IStripe,
  ITimeToShip,
} from '../../@types'
import {
  ErrorMsgs,
  mediaQueries,
  PageTitles,
  PageURLs,
  stripePubKey,
  windowWithStripe,
} from '../../vars'
import { Layout } from '../common/Layout'
import { formInfoReducer, IAction } from './InfoForm/formInfoReducer'
import { InfoForm } from './InfoForm'
import { priceInfoReducer } from './Shipping/priceInfoReducer'
import { BackButton, Container, ErrorMsg, Header } from './styles'
import { Shipping } from './Shipping'

export enum CheckoutStages {
  Info     = 'Info',
  Shipping = 'Shipping',
}

const initialFormInfo = {
  name      : '',
  email     : '',
  phone     : '',
  line1     : '',
  line2     : '',
  city      : '',
  province  : '',
  country   : 'CA',
  postalCode: '',
}

export interface IOrderInfo {
  orderId: string
  orderDescription: string
  selectedShippingMethodId: string
  skuItems: IRedirectToCheckoutItem[]
}

interface IProps {
  currentCart: cart | null
  minPrice: number
  timeToShip: ITimeToShip
  calgaryFreeShippingText: string
}

export const CheckoutPage = ({ currentCart, minPrice, timeToShip, calgaryFreeShippingText }: IProps) => {
  if (!windowWithStripe || !windowWithStripe.location) {
    return null
  }

  if (!currentCart) {
    return (
      <Layout currentPageTitle={PageTitles.Checkout} darkBg={false}>
        <ErrorMsg>{ErrorMsgs.cart}</ErrorMsg>
      </Layout>
    )
  }

  const subTotal = currentCart.reduce(
    (acc, item) => acc + item.quantity * item.selectedVariant.price,
    0,
  )

  const expectedMinCartPrice = currentCart.reduce(
    (acc, item) => acc + item.quantity * minPrice,
    0,
  )

  const stripe = useRef<IStripe | false>()

  useEffect(() => {
    try {
      stripe.current = windowWithStripe && windowWithStripe.Stripe(stripePubKey)
    } catch (e) {
      setError(ErrorMsgs.stripe)
    }
  }, [])
  const [orderInfo, setOrderInfo]             = useState<IOrderInfo>()
  const [priceInfo, setPriceInfo]             = useReducer(
    priceInfoReducer,
    undefined,
  )
  const [formInfo, setFormInfo]               = useReducer(
    formInfoReducer,
    initialFormInfo,
  )
  const [formInfoChanged, setFormInfoChanged] = useState(false)
  const [stage, setStage]                     = useState<CheckoutStages>(
    CheckoutStages.Info)
  const [error, setError]                     = useState<ErrorMsgs | false>(
    false)

  async function goToCheckout() {
    if (stripe.current && orderInfo && windowWithStripe) {
      const items: IRedirectToCheckoutItem[] = [
        ...orderInfo.skuItems,
        { sku: orderInfo.selectedShippingMethodId, quantity: 1 },
      ]

      try {
        await stripe.current.redirectToCheckout({
          items,
          successUrl   : `${windowWithStripe.location.origin}${PageURLs.PurchaseSuccess}`,
          cancelUrl    : `${windowWithStripe.location.origin}${PageURLs.Checkout}`,
          customerEmail: formInfo ? formInfo.email : '',
        })
      } catch (err) {
        if (err && err.toString().includes('email')) {
          setError(ErrorMsgs.email)
        } else {
          setError(ErrorMsgs.stripe)
        }
      }
    } else {
      setError(ErrorMsgs.stripe)
    }
  }

  function handleFormChange(action: IAction) {
    setFormInfo(action)

    if (formInfo[action.type] !== action.newValue) {
      setFormInfoChanged(true)
    }
  }

  function handleSubmit() {
    setStage(CheckoutStages.Shipping)
    setError(false)
  }

  function goBack() {
    setStage(CheckoutStages.Info)
  }

  useEffect(() => {
    setFormInfoChanged(false)
  }, [priceInfo])

  const isHandheld = useMediaQuery(mediaQueries.general.handheld)

  return (
    <Layout currentPageTitle={PageTitles.Checkout} darkBg={false}>
      {subTotal < expectedMinCartPrice ? (
        <ErrorMsg>{ErrorMsgs.cart}</ErrorMsg>
      ) : (
        <Container>
          <Header>
            <h1>
              Checkout <span>/ {stage}</span>
            </h1>
          </Header>

          {error && <ErrorMsg>{error}</ErrorMsg>}

          {stage === CheckoutStages.Info && error !== ErrorMsgs.stripe && (
            <InfoForm
              handleSubmit={handleSubmit}
              handleFormChange={handleFormChange}
              formInfo={formInfo}
            />
          )}

          {stage === CheckoutStages.Shipping && formInfo && (
            <Shipping
              totalPriceBody={{ formInfo, cart: currentCart, timeToShip, calgaryFreeShippingText }}
              orderInfo={orderInfo}
              setOrderInfo={setOrderInfo}
              goToCheckout={goToCheckout}
              priceInfo={priceInfo}
              setPriceInfo={setPriceInfo}
              fetchData={formInfoChanged}
            />
          )}

          {stage === CheckoutStages.Shipping && (
            <BackButton onClick={goBack}>
              <img src={backArrow} alt='Back arrow' />
              {isHandheld ? '' : ' Back'}
            </BackButton>
          )}
        </Container>
      )}
    </Layout>
  )
}
