import React from 'react'
import InputMask from 'react-input-mask'

import helpIcon from '../../../../static/svg/help.svg'

import { IFormInfo } from '../../../@types'
import { email as larrysEmail } from '../../../vars'
import { FormInfoActions, IAction } from './formInfoReducer'
import {
  Contact,
  CountryText,
  CountryTooltip,
  Form,
  Shipping,
  Submit,
} from './styles'

interface IProps {
  formInfo: IFormInfo

  handleFormChange(action: IAction): void

  handleSubmit(): void
}

export function InfoForm({ handleSubmit, handleFormChange, formInfo }: IProps) {
  const {
          name,
          email,
          city,
          line1,
          line2,
          phone,
          postalCode,
          province,
        } = formInfo

  function handleNameChange(e: React.FormEvent<HTMLInputElement>) {
    handleFormChange({
      type    : FormInfoActions.Name,
      newValue: e.currentTarget.value,
    })
  }

  function handleEmailChange(e: React.FormEvent<HTMLInputElement>) {
    handleFormChange({
      type    : FormInfoActions.Email,
      newValue: e.currentTarget.value,
    })
  }

  function handlePhoneChange(e: React.FormEvent<HTMLInputElement>) {
    handleFormChange({
      type    : FormInfoActions.Phone,
      newValue: e.currentTarget.value,
    })
  }

  function handleLine1Change(e: React.FormEvent<HTMLInputElement>) {
    handleFormChange({
      type    : FormInfoActions.Line1,
      newValue: e.currentTarget.value,
    })
  }

  function handleLine2Change(e: React.FormEvent<HTMLInputElement>) {
    handleFormChange({
      type    : FormInfoActions.Line2,
      newValue: e.currentTarget.value,
    })
  }

  function handleCityChange(e: React.FormEvent<HTMLInputElement>) {
    handleFormChange({
      type    : FormInfoActions.City,
      newValue: e.currentTarget.value,
    })
  }

  function handleProvinceChange(e: React.FormEvent<HTMLInputElement>) {
    handleFormChange({
      type    : FormInfoActions.Province,
      newValue: e.currentTarget.value,
    })
  }

  function handlePostalCodeChange(e: React.FormEvent<HTMLInputElement>) {
    handleFormChange({
      type    : FormInfoActions.PostalCode,
      newValue: e.currentTarget.value,
    })
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <Form
      onSubmit={onSubmit}
      id='info-form'
      name='info-form'
      acceptCharset='utf-8'
    >
      <Contact>
        <h2>Contact</h2>

        <label htmlFor='name'>
          Full name
          <input
            id='name'
            onChange={handleNameChange}
            value={name}
            type='text'
            name='name'
            required={true}
            aria-labelledby='name'
          />
        </label>

        <label htmlFor='email'>
          Email
          <input
            id='email'
            value={email}
            onChange={handleEmailChange}
            type='email'
            name='email'
            required={true}
            aria-labelledby='email'
          />
        </label>

        <label htmlFor='phone'>
          Phone
          <InputMask
            mask='(999) 999 9999'
            maskChar=' '
            id='phone'
            value={phone}
            onChange={handlePhoneChange}
            type='tel'
            name='phone'
            required={true}
            aria-labelledby='phone'
          />
        </label>
      </Contact>

      <Shipping>
        <h2>Shipping</h2>

        <label htmlFor='line1'>
          Address line 1 (Street address/PO Box/Company name)
          <input
            id='line1'
            value={line1}
            onChange={handleLine1Change}
            type='text'
            name='line1'
            required={true}
            aria-labelledby='line1'
          />
        </label>

        <label htmlFor='line2'>
          Address line 2 (Apartment/Suite/Unit/Building)
          <input
            id='line2'
            value={line2}
            onChange={handleLine2Change}
            type='text'
            name='line2'
            aria-labelledby='line2'
          />
        </label>

        <label htmlFor='city'>
          City
          <input
            id='city'
            value={city}
            onChange={handleCityChange}
            type='text'
            name='city'
            required={true}
            aria-labelledby='city'
          />
        </label>

        <label htmlFor='province'>
          Province
          <input
            id='province'
            value={province}
            onChange={handleProvinceChange}
            type='text'
            name='province'
            required={true}
            aria-labelledby='province'
          />
        </label>

        <label htmlFor='country'>
          <CountryText>
            Country{' '}
            <CountryTooltip>
              <img src={helpIcon} alt='Help icon'/>
              <span>
                At the moment automated orders are only supported for those
                ordering within Canada. Please contact me at{' '}
                <a href={`mailto:${larrysEmail}`}>{larrysEmail}</a> if you would
                like to place an order outside of Canada.
              </span>
            </CountryTooltip>
          </CountryText>
          <input
            id='country'
            value='Canada'
            readOnly={true}
            type='text'
            name='country'
            required={true}
            aria-labelledby='country'
          />
        </label>

        <label htmlFor='postal-code'>
          Postal code
          <InputMask
            mask='*** ***'
            maskChar=' '
            id='postal-code'
            value={postalCode}
            onChange={handlePostalCodeChange}
            type='text'
            name='postal-code'
            required={true}
            aria-labelledby='postal-code'
          />
        </label>
      </Shipping>

      <Submit type='submit' value='Continue' name='submit' form='info-form'/>
    </Form>
  )
}
