import styled from 'styled-components'
import { rhythm } from '../../../utils/typography'
import { mediaQueries } from '../../../vars'

export const Form = styled.form`
  display: grid;
  margin: ${rhythm(1)};
  max-width: 900px;

  &,
  & > * {
    gap: ${rhythm(1)};
  }

  label {
    font-size: 0.8rem;
    color: #333;
  }

  input:not([type='submit']) {
    background: #fff;
    border: 1px solid #00000022;
    box-shadow: none;
    border-radius: 2px;
    padding: ${rhythm(1 / 4)};
  }

  h2 {
    font-size: 1.3rem;
    color: #333;
  }
`

export const Contact = styled.section`
  display: grid;
  --label-width: 50%;

  grid-template: repeat(3, 1fr) / 1fr 2fr;

  h2 {
    grid-area: span 4 / 1;
  }

  label {
    width: var(--label-width);
  }

  input {
    width: 100%;
  }

  label:nth-of-type(3) {
    width: calc(var(--label-width) / 3 * 2);
  }

  @media ${mediaQueries.general.handheld} {
    --label-width: 100%;
    grid-template: initial;

    h2 {
      margin: 0;
      grid-area: initial;
    }
  }
`

export const Shipping = styled.section`
  display: grid;

  grid-template: repeat(3, 1fr) / repeat(3, 1fr);

  h2 {
    grid-area: span 4 / 1;
  }

  label:nth-of-type(1) {
    grid-column: 2 / 4;
  }

  label {
    width: 100%;
  }

  label > input {
    width: 100%;
  }

  label:nth-of-type(1) {
    width: 100%;
  }

  label:last-child {
    width: calc(100% / 3);
  }

  @media ${mediaQueries.general.handheld} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template: initial;

    label {
      width: calc(100% / 3 * 2);
    }

    input {
      width: 100%;
    }

    h2 {
      margin: 0;
      grid-area: initial;
    }
    label:nth-of-type(1) {
      grid-column: initial;
    }
  }
`

export const Submit = styled.input`
  font-weight: bold;
  border: 1px solid var(--primary);
  cursor: pointer;
  background: #fff;
  padding: ${rhythm(1 / 3)};
  margin-top: ${rhythm(1 / 2)};
  box-shadow: var(--box-shadow-light);
`

export const CountryText = styled.div`
  display: flex;
  align-items: center;
`

export const CountryTooltip = styled.div`
  --background: var(--primary-light);

  position: relative;
  display: inline-block;
  height: 1rem;

  img {
    margin-bottom: 0;
    margin-left: 3px;
    height: 1rem;
  }

  span {
    color: var(--black);
    background: var(--background);
    padding: ${rhythm(1)};
    border-radius: 10px;
    box-shadow: var(--box-shadow-very-light);
    position: absolute;
    z-index: 1;
    visibility: hidden;
    width: 300px;
    bottom: 98%;
    left: 50%;
    margin-left: -147px;
    opacity: 0;
    transition: 0.5s opacity;
  }

  span::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--background) transparent transparent transparent;
  }

  :hover span {
    visibility: visible;
    opacity: 1;
  }

  @media ${mediaQueries.general.handheld} {
    span {
      margin-left: -20vw;
    }

    span::after {
      left: 20vw;
    }
  }
`
