import React from 'react'

import { formatCentsToDollars } from '../../../functions/formatCentsToDollars'
import { IShippingItem } from '../../../lambda/get-total/get-total'
import {
  Description,
  DollarSign,
  Estimate,
  Price,
  Radio,
  Wrapper,
} from './styles'

interface IProps {
  selectedShippingMethodId: string
  shippingMethod: IShippingItem
  changeHandler: (shippingMethod: IShippingItem) => void
}

export function ShippingRadio({
  selectedShippingMethodId,
  shippingMethod,
  changeHandler,
}: IProps) {
  function handleChange() {
    changeHandler(shippingMethod)
  }

  const labelId = `${shippingMethod.id}-label`

  return (
    <Radio
      id={shippingMethod.id}
      selectedId={selectedShippingMethodId}
      radioGroup='shipping-methods'
      changeHandler={handleChange}
      key={shippingMethod.id}
      ariaLabelledBy={labelId}
    >
      <Wrapper>
        <Description id={labelId}>{shippingMethod.description}</Description>
        <Price>
          <DollarSign>$</DollarSign>
          {formatCentsToDollars(shippingMethod.amount)}
          <Estimate> / {shippingMethod.delivery_estimate}</Estimate>
        </Price>
      </Wrapper>
    </Radio>
  )
}

