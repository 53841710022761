import React from 'react'

import { cart, ITimeToShip } from '../@types'
import { CheckoutPage } from '../components/CheckoutPage'
import { ErrorBoundary } from '../components/common/ErrorBoundary'
import { getCurrentCart } from '../functions/getCurrentCart'
import { graphql } from 'gatsby'

interface IProps {
  data: {
    allSanityPhotoVariant: {
      nodes: Array<{ price: { amount: number } }>
    }
    sanityTimeToShip: ITimeToShip
    sanitySiteCopy: {
      calgaryFreeShippingText: string
    }
  }
  location: { state?: { currentCart: cart } }
}

export default function index({ data, location: { state } }: IProps) {
  const minPrice = data.allSanityPhotoVariant.nodes[0].price.amount

  const currentCart =
          state && state.currentCart ? state.currentCart : getCurrentCart()

  return (
    <ErrorBoundary>
      <CheckoutPage
        currentCart={currentCart}
        minPrice={minPrice}
        timeToShip={data.sanityTimeToShip}
        calgaryFreeShippingText={data.sanitySiteCopy.calgaryFreeShippingText}
      />
    </ErrorBoundary>
  )
}

export const query = graphql`
    {
        allSanityPhotoVariant(sort: { fields: price___amount, order: ASC }) {
            nodes {
                price {
                    amount
                }
            }
        }
        sanityTimeToShip {
            earliest
            latest
        }
        sanitySiteCopy {
            calgaryFreeShippingText
        }
    }
`
