import { IPriceInfo } from '../../../@types/index.d'

export enum PriceInfoActions {
  PriceInfo = 'updatePriceInfo',
  ShippingPrice = 'shippingPrice',
}

export interface IAction {
  type: PriceInfoActions
  newValue: IPriceInfo | string
}

export function priceInfoReducer(
  state: IPriceInfo | undefined,
  action: IAction
): IPriceInfo | undefined {
  switch (action.type) {
    case PriceInfoActions.PriceInfo:
      if (typeof action.newValue === 'object') {
        return action.newValue
      } else {
        return state
      }

    case PriceInfoActions.ShippingPrice:
      if (typeof action.newValue === 'string' && state) {
        const total = (
          parseFloat(state.total) -
          parseFloat(state.shippingPrice) +
          parseFloat(action.newValue)
        )
          .toFixed(2)
          .toString()

        return { ...state, shippingPrice: action.newValue, total }
      } else {
        return state
      }

    default:
      return state
  }
}
