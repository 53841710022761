export function formatCentsToDollars(total: number): string {
  if (total === 0) return '0'

  const charArrFromTotal: string[] = total.toString().split('')

  while (charArrFromTotal.length < 3) {
    charArrFromTotal.unshift('0')
  }

  charArrFromTotal.splice(-2, 0, '.')

  const formattedTotal: string = charArrFromTotal.join('')

  return formattedTotal
}
