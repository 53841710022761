import React, { Component, ErrorInfo, ReactElement } from 'react'

interface IProps {
  children: ReactElement[] | ReactElement
}

interface IState {
  error: Error | null
  errorInfo: ErrorInfo | null
}

export class ErrorBoundary extends Component {
  public state: IState
  constructor(props: IProps) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  public render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      )
    }
    return this.props.children
  }
}
