import { AsyncTask, useAsyncRun, useAsyncTask } from 'react-hooks-async'

type UseAsyncTaskFetch = <Result>(
  fetchData: boolean,
  input: string | Request,
  init?: RequestInit,
  bodyReader?: (r: Response) => Promise<Result>
) => AsyncTask<Result | null>

const defaultInit = {}
const defaultReadBody = (response: Response) => response.json()

const useAsyncTaskFetch: UseAsyncTaskFetch = (
  fetchData,
  input,
  init = defaultInit,
  readBody = defaultReadBody
) => {
  return useAsyncTask(
    async abortController => {
      if (fetchData) {
        const response = await fetch(input, {
          signal: abortController.signal,
          ...init,
        })

        const body = await readBody(response)

        return body
      } else {
        return null
      }
    },
    [input, init, readBody]
  )
}

export const useFetch: UseAsyncTaskFetch = (...args) => {
  const asyncTask = useAsyncTaskFetch(...args)

  useAsyncRun(asyncTask)

  return asyncTask
}
