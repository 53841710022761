import React, { ReactElement, useMemo, useRef, useState } from 'react'

import heart from '../../../../static/heart.svg'

import { IOrderInfo } from '..'
import {
  IPriceInfo,
  IRedirectToCheckoutItem,
  ITotalPriceBody,
  ITotalPriceResponseBody,
} from '../../../@types'
import { formatCentsToDollars } from '../../../functions/formatCentsToDollars'
import { useFetch } from '../../../hooks/useFetch'
import { IShippingItem } from '../../../lambda/get-total/get-total'
import { Api, ErrorMsgs } from '../../../vars'
import { ShippingRadio } from '../ShippingRadio'
import { ErrorMsg } from '../styles'
import { IAction, PriceInfoActions } from './priceInfoReducer'
import { Container, Pending, ShippingFieldset, Sidebar } from './styles'

interface IUseFetch {
  pending: boolean
  error: Error | null
  result: ITotalPriceResponseBody | string
}

interface IProps {
  totalPriceBody: ITotalPriceBody
  orderInfo: IOrderInfo | undefined
  setOrderInfo: React.Dispatch<React.SetStateAction<IOrderInfo | undefined>>
  priceInfo: IPriceInfo | undefined
  setPriceInfo: React.Dispatch<IAction>
  fetchData: boolean

  goToCheckout(): void
}

export function Shipping({
  totalPriceBody,
  setOrderInfo,
  orderInfo,
  priceInfo,
  setPriceInfo,
  goToCheckout,
  fetchData,
}: IProps) {
  const [errorMsg, setErrorMsg] = useState<ErrorMsgs | false>(false)

  const resultRetrieved = useRef<boolean>(!fetchData)

  const memoizedOpts: RequestInit = useMemo(
    () => ({
      method : 'POST',
      headers: {
        Accept        : 'application/json',
        'Content-Type': 'application/json',
      },
      body   : JSON.stringify(totalPriceBody),
    }),
    [],
  )

  const fetchResponse: IUseFetch = useFetch(
    fetchData,
    Api.getTotal,
    memoizedOpts,
    bodyReader,
  )

  const { error, result } = fetchResponse

  const pending = fetchData ? fetchResponse.pending : false

  if (fetchData) {
    if (!errorMsg && !pending) {
      if (error || !result) {
        setErrorMsg(ErrorMsgs.default)
      } else if (typeof result === 'string') {
        setErrorMsg(result as ErrorMsgs)
      }
    }

    if (result && typeof result === 'object' && !resultRetrieved.current) {
      (function handleResult() {
        resultRetrieved.current = true

        const {
                orderId,
                orderDescription,
                shippingItems,
                total,
                orderItems,
              } = result

        const selectedShippingMethod   = shippingItems[0]
        const selectedShippingMethodId = selectedShippingMethod.id

        const formattedTotal         = formatCentsToDollars(total)
        const formattedShippingPrice = formatCentsToDollars(
          selectedShippingMethod.amount,
        )

        const newPriceInfo: IPriceInfo = {
          skus         : [],
          shippingItems,
          shippingPrice: formattedShippingPrice,
          total        : formattedTotal,
        }

        const skuItems: IRedirectToCheckoutItem[] = []

        orderItems.forEach(item => {
          const { amount, description, quantity, id } = item

          newPriceInfo.skus.push({ description, quantity, amount })

          if (id) {
            skuItems.push({ sku: id, quantity })
          } else {
            setErrorMsg(ErrorMsgs.default)
          }
        })

        setOrderInfo({
          orderId,
          orderDescription,
          selectedShippingMethodId,
          skuItems,
        })
        setPriceInfo({
          type    : PriceInfoActions.PriceInfo,
          newValue: newPriceInfo,
        })
        setErrorMsg(false)
      })()
    }
  }

  function handleShippingMethodChange(shippingItem: IShippingItem) {
    setPriceInfo({
      type    : PriceInfoActions.ShippingPrice,
      newValue: formatCentsToDollars(shippingItem.amount),
    })

    setOrderInfo(oldOrderInfo => {
      if (oldOrderInfo) {
        return {
          ...oldOrderInfo,
          selectedShippingMethodId: shippingItem.id,
        }
      } else {
        return undefined
      }
    })
  }

  if (errorMsg) {
    return <ErrorMsg>{errorMsg}</ErrorMsg>
  } else if (pending) {
    return (
      <Pending>
        Calculating shipping, please wait <img src={heart} alt='Heart' />
      </Pending>
    )
  } else if (priceInfo && orderInfo && !pending) {
    const shippingMethodRadios = getShippingMethodRadios(
      priceInfo,
      orderInfo,
      handleShippingMethodChange,
    )

    return (
      <Container>
        <ShippingFieldset>
          <legend>Please select a shipping method:</legend>
          {shippingMethodRadios}
        </ShippingFieldset>
        <Sidebar>
          <label htmlFor='total'>
            Total: <span id='total'>${priceInfo.total}</span>
          </label>
          <button onClick={goToCheckout}>Continue</button>
        </Sidebar>
      </Container>
    )
  } else {
    return null
  }
}

function getShippingMethodRadios(
  priceInfo: IPriceInfo,
  orderInfo: IOrderInfo,
  handleShippingMethodChange: (shippingItem: IShippingItem) => void,
): Array<ReactElement | null> | undefined {
  return priceInfo.shippingItems.map((shippingItem, index) => {
    const selectedId = orderInfo.selectedShippingMethodId
      ? orderInfo.selectedShippingMethodId
      : index === 0
        ? shippingItem.id
        : ''

    return (
      <ShippingRadio
        selectedShippingMethodId={selectedId}
        shippingMethod={shippingItem}
        changeHandler={handleShippingMethodChange}
        key={shippingItem.id}
      />
    )
  })
}

function bodyReader(response: Response) {
  if (response.ok) {
    return response.json()
  } else {
    return response.text()
  }
}
